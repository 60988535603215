@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://use.typekit.net/wnm2esy.css');

body {
    margin: 0;
    font-family: poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@layer base {
    table {
        @apply table-auto border-t border-separate border-white;
    }

    #bord-row th:first-child {
        @apply border-l border-zxblue-to rounded-l-xl;
    }

    #bord-row th:last-child {
        @apply border-r border-zxblue-to rounded-r-xl;
    }

    #bord-row th {
        @apply border-y border-zxblue-to;
    }

    #muster-col1 {
        @apply lg:text-sm md:text-xs border border-zxblue-to border-r-white text-base rounded-l-lg;
    }

    #muster-col2 {
        @apply lg:text-sm md:text-xs border border-zxblue-to border-l-white text-base rounded-r-lg;
    }

    #emp-info-grid {
        @apply pl-11 pb-4;
    }
}

@layer components {
    .btn-yellow {
        @apply border lg:w-48 md:w-40 lg:h-12 md:h-10 border-zxbutton2-to rounded-full;
    }

    .btn-blue {
        @apply border lg:w-48 md:w-40 lg:h-12 md:h-10 bg-gradient-to-r to-zxblue-to hover:bg-gradient-to-t from-zxblue-from rounded-full text-white;
    }
}

.react-calendar {
    background-color: #fff;
    color: #4A5660;
    filter: drop-shadow(2px 16px 19px rgba(0, 0, 0, 0.09));
    backdrop-filter: blur(40px);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 2.5em;
    border-style: solid;
    border-radius: 8px;
    border-color: #7b8bda;
    border-width: 2px;
    padding-top: 2px;
    padding-left: 3px;
}
.react-calendar__navigation button {
    background: #9b51e0;
    min-width: 30px;
    background: none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 90%;
    line-height: 14px;
    align-items: center;
    margin-top: 8px;
    color: #4a5660;
    padding-left: 12%;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
abbr[title] {
    width: 21px;
    height: 12px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #828282;
    margin-left: 9px;
    text-decoration: none;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #6f48eb;
}
/* .react-calendar__tile--now {
    background: linear-gradient(232.37deg, #FE73C6 -8.39%, #738DFE 108.39%);
    border-radius: 25px;
    color: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: linear-gradient(232.37deg, #FE73C6 -8.39%, #738DFE 108.39%);
    border-radius: 25px;
    color: #4A5660;
} */
.react-calendar__tile--hasActive {
    background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}
.react-calendar__tile--active {
    background: linear-gradient(232.37deg, #fe73c6 -8.39%, #738dfe 108.39%);
    border-radius: 25px;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: linear-gradient(232.37deg, #FE73C6 -8.39%, #738DFE 108.39%);
    color: #4A5660;
}
